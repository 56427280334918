import React, { useState, useEffect, useCallback, useRef, useLayoutEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { api } from 'api/api';

import * as EmailValidator from 'email-validator';

import classNames from 'classnames';

import { useMeasure, useLocalStorage } from "react-use";
import { useStayScrolled } from 'hooks/useStayScrolled';
import { useDisabledScrollWidth } from 'hooks/useDisabledScrollWidth';

import { useTransition, useSpring, a, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';
import { useGesture } from 'react-use-gesture';
import { DragConfig, FullGestureState } from 'react-use-gesture/dist/types';
import { useInertialSpring } from 'hooks/useInertialSpring';
import { IDraggableBounds } from 'hooks/useDraggable';

import { ModalTrigger } from './ModalTrigger';
import { ModalMessage } from './ModalMessage';
import { InputShortText } from './ChatInputs/InputShortText';
import { InputLongText } from './ChatInputs/InputLongText';
import { RadioButtons } from './ChatInputs/RadioButtons';
import { Checkboxes } from './ChatInputs/Checkboxes';

import * as fromChatScript from './ChatScript';

import * as fromInterfaceOverlay from 'store/InterfaceOverlay';

import SimpleLink from 'components/Links/SimpleLink/SimpleLink';

import { uuidv4, useTimeout, useLatest, usePrevious } from 'utils/Index';

export type IBackgroundShade = 'light' | 'dark';

interface ILocalChatState {
  timeUpdated: Date;
  id: string;
  messages: fromInterfaceOverlay.IMessage[];
  formValues: fromInterfaceOverlay.IFormValue[];
  currentScriptStepId: string;
  nextScriptStepId: string;
  currentUserInputId: string;
  //chatStarted: boolean;
}

export interface IProps {
  backgroundShade: IBackgroundShade;
  mainSiteOverlay: boolean;
}

const ChatModal: React.FC<IProps> = ({
  mainSiteOverlay,
  backgroundShade,
}) => {

  const [initialised, setInitialised] = useState(false);
  const previousInitialised = usePrevious(initialised);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Redux functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const active = useSelector(fromInterfaceOverlay.getChatActive);
  const open = useSelector(fromInterfaceOverlay.getChatModalWindow);
  const chatId = useSelector(fromInterfaceOverlay.getChatModalId);
  const chatStarted = useSelector(fromInterfaceOverlay.getChatModalStarted);

  const messages = useSelector(fromInterfaceOverlay.getChatModalMessages);
  const previousMessages: fromInterfaceOverlay.IMessage[] = usePrevious(messages);

  const formValues = useSelector(fromInterfaceOverlay.getChatModalFormValues);
  const latestFormValues = useLatest(formValues);

  const currentScriptStepId = useSelector(fromInterfaceOverlay.getChatModalCurrentScriptStepId);
  const nextScriptStepId = useSelector(fromInterfaceOverlay.getChatModalNextScriptStepId);
  const currentUserInputId = useSelector(fromInterfaceOverlay.getChatModalCurrentUserInputId);
  const userInputVisible = useSelector(fromInterfaceOverlay.getChatModalUserInputVisible);

  const triggerWidth = useSelector(fromInterfaceOverlay.getChatTriggerWidth);

  const dispatch = useDispatch();

  const closeChatModalAction = useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.closeChatModalAction())
    , [dispatch]);

  const setChatId = useCallback(
    (id: string) => dispatch(fromInterfaceOverlay.actionCreators.setChatId(id))
    , [dispatch]);

  const startChat = useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.startChat())
    , [dispatch]);

  const loadChatMessages = useCallback(
    (chatMessages: fromInterfaceOverlay.IMessage[]) => dispatch(fromInterfaceOverlay.actionCreators.loadChatMessages(chatMessages))
    , [dispatch]);

  const addChatMessage = useCallback(
    (message: fromInterfaceOverlay.IMessage) => dispatch(fromInterfaceOverlay.actionCreators.addChatMessage(message))
    , [dispatch]);

  const loadFormValues = useCallback(
    (newFormValues: fromInterfaceOverlay.IFormValue[]) => dispatch(fromInterfaceOverlay.actionCreators.loadFormValues(newFormValues))
    , [dispatch]);

  const addFormValue = useCallback(
    (formValue: fromInterfaceOverlay.IFormValue) => dispatch(fromInterfaceOverlay.actionCreators.addFormValue(formValue))
    , [dispatch]);

  const resetFormValues = useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.resetFormValues())
    , [dispatch]);

  const updateCurrentScriptStepId = useCallback(
    (id: string, isCaller: boolean = true) => dispatch(fromInterfaceOverlay.actionCreators.updateCurrentScriptStepId(id, isCaller))
    , [dispatch]);

  const updateNextScriptStepId = useCallback(
    (id: string, isCaller: boolean = true) => dispatch(fromInterfaceOverlay.actionCreators.updateNextScriptStepId(id, isCaller))
    , [dispatch]);

  const updateCurrentUserInputId = useCallback(
    (id: string, isCaller: boolean = true) => dispatch(fromInterfaceOverlay.actionCreators.updateCurrentUserInputId(id, isCaller))
    , [dispatch]);

  const updateUserInputVisibility = useCallback(
    (visible: boolean) => dispatch(fromInterfaceOverlay.actionCreators.updateUserInputVisibility(visible))
    , [dispatch]);

  const markChatAsActive = useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.markChatAsActive())
    , [dispatch]);

  const updateChatTriggerWidth = useCallback(
    (width: number) => dispatch(fromInterfaceOverlay.actionCreators.updateChatTriggerWidth(width))
    , [dispatch]);

  const addChatNotificationDot = useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.addChatNotificationDot())
    , [dispatch]);

  const notificationDot = useSelector(fromInterfaceOverlay.getChatModalNotificationDot);
  const removeChatNotificationDot = useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.removeChatNotificationDot())
    , [dispatch]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Window dimension change functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const windowWrapper = useRef<HTMLDivElement>(null);
  const [windowHeight, setWindowHeight] = useState(100);
  const previousWindowHeight = usePrevious(windowHeight);
  const latestWindowHeight = useLatest(windowHeight);

  const triggerNotificationElement = useRef<HTMLDivElement>(null);

  const updateWindowDimensions = () => {
    if (mainSiteOverlay) {
      const triggerNotification = triggerNotificationElement.current;
      if (triggerNotification != null)
        updateChatTriggerWidth(triggerNotification.clientWidth);

      const windowWrapperProps = windowWrapper.current.getBoundingClientRect();
      const height = windowWrapperProps.height;
      setWindowHeight(height);
    }
  };

  useLayoutEffect(() => {

    updateWindowDimensions();

    // Setup event listeners on initial mount to keep the  dimensions of the slider updated
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat local storage functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const getEmptyLocalChatStateObject = (): ILocalChatState => ({
    timeUpdated: new Date(),
    id: null,
    messages: [],
    formValues: [],
    currentScriptStepId: null,
    nextScriptStepId: null,
    currentUserInputId: null,
  });
  const [localChatState, setLocalChatState] = useLocalStorage<ILocalChatState>('chatState', getEmptyLocalChatStateObject());

  useEffect(() => {
    if (mainSiteOverlay && active && initialised && messages.length > 0) {
      updateLocalChatState(
        chatId,
        messages,
        formValues,
        currentScriptStepId,
        nextScriptStepId,
        currentUserInputId,
        //chatStarted,
      );
    }
  }, [
    mainSiteOverlay,
    active,
    initialised,
    chatId,
    messages,
    formValues,
    currentScriptStepId,
    nextScriptStepId,
    currentUserInputId,
    //chatStarted,
  ]);

  useEffect(() => {
    if (mainSiteOverlay) {
      let newChatSession = true;
      let newChatId: string = null;
      try {
        if (localChatState.id !== null) {
          const expiryDate = new Date(localChatState.timeUpdated);
          expiryDate.setMinutes(expiryDate.getMinutes() + 20);
          // const expiryDate = new Date(localChatState.timeUpdated.getTime() + (20 * 60000)); // Add 20 minutes to the time updated for an expiry time for chat state
          // const expiryDate = new Date();
          if (new Date() < expiryDate) {
            newChatSession = false;
            newChatId = localChatState.id;
            if (localChatState.messages.length > 0 || localChatState.currentScriptStepId != null || localChatState.nextScriptStepId != null || localChatState.currentUserInputId != null) {
              // markChatAsActive(); // This will mark the chat as active so any chat with no messages can trigger the first step of the chat script, previously it would restore the chat from the cookie and assume that the user was the one we were waiting to send a message for
              // } else {
              loadChatMessages(localChatState.messages);
              loadFormValues(localChatState.formValues);
              updateCurrentScriptStepId(localChatState.currentScriptStepId, false);
              updateNextScriptStepId(localChatState.nextScriptStepId, false);
              updateCurrentUserInputId(localChatState.currentUserInputId, false);
            }
          } else {
            setLocalChatState(getEmptyLocalChatStateObject());
          }
        }
      }
      catch {
        newChatSession = true;
      }

      if (newChatSession) {
        newChatId = uuidv4();
        updateLocalChatState(
          newChatId,
          messages,
          formValues,
          currentScriptStepId,
          nextScriptStepId,
          currentUserInputId,
          //      chatStarted,
        );
      }
      setChatId(newChatId);
      setInitialised(true);
    }
  }, [mainSiteOverlay]);

  const updateLocalChatState = (
    cookieChatId: string,
    cookieMessages: fromInterfaceOverlay.IMessage[],
    cookieFormValues: fromInterfaceOverlay.IFormValue[],
    cookieCurrentScriptStepId: string,
    cookieNextScriptsStepId: string,
    cookeCurrentUserInputId: string,
    //cookieChatStarted: boolean,
  ) => {
    const chatState: ILocalChatState = {
      timeUpdated: new Date(),
      id: cookieChatId,
      messages: cookieMessages,
      formValues: cookieFormValues,
      currentScriptStepId: cookieCurrentScriptStepId,
      nextScriptStepId: cookieNextScriptsStepId,
      currentUserInputId: cookeCurrentUserInputId,
      //chatStarted: cookieChatStarted,
    };
    setLocalChatState(chatState);
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat modal functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 400,
  };

  const [windowWidthSpring, setWindowWidthSpring] = useSpring(() => ({
    x: 0,
    config: springConfig,
  }));

  useEffect(() => {
    setModalPosition({ y: open ? 0 : windowHeight, config: springConfig });
    setWindowWidthSpring({ x: open ? 1 : 0 });
    updateWindowDimensions();
  }, [open]);

  const closeChatModal = () => {
    if (open)
      closeChatModalAction();
  };

  useEffect(() => {
    if (mainSiteOverlay && initialised && !chatStarted && messages.length > 0) {
      startChat();
    }
  }, [mainSiteOverlay, initialised, chatStarted, messages.length]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat modal drag functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [{ y: modalYPosition }, setModalPosition] = useInertialSpring({ x: 0, y: latestWindowHeight.current });
  const [isBeingDragged, setIsBeingDragged] = useState(false);

  const bounds: IDraggableBounds = {
    y: [0, latestWindowHeight.current],
    x: [0, 0],
  };

  const gestureOptions: DragConfig = {
    initial: () => [0, modalYPosition.get()],
    rubberband: true,
    lockDirection: true,
    axis: 'y',
    bounds: {
      top: bounds.y[0],
      bottom: bounds.y[1],
      left: bounds.x[0],
      right: bounds.x[1],
    },
  };
  const dragBarBind = useGesture(
    { onDrag: state => handleDragBarDrag(state) },
    { drag: { ...gestureOptions, filterTaps: true } },
  );

  const chatContainerBind = useGesture(
    { onDrag: state => handleChatContainerDrag(state) },
    { drag: { ...gestureOptions, filterTaps: true } },
  );

  const handleChatContainerDrag = (state: FullGestureState<'drag'>) => {
    const { movement: [, my], delta: [, dy], first, cancel } = state;
    const messagesContainer = messagesContainerRef.current;
    const currentScrollTop = messagesContainer.scrollTop;

    if (first && (currentScrollTop !== 0 || dy < 0))
      cancel();

    if (!first && currentScrollTop === 0)
      handleDragBarDrag(state);
  };

  const handleDragBarDrag = (state: FullGestureState<'drag'>) => {
    const { movement: [mx, my], vxvy: [vx, vy], first, last, cancel, canceled } = state;

    if (first)
      setIsBeingDragged(true);
    else if (last)
      setIsBeingDragged(false);

    // if the user drags the modal up past a threshold, then we cancel
    // the drag so that the modal resets to its fully open position
    if (my < -5) cancel();

    const commonConfig = { bounds, velocity: { x: vx, y: vy } };

    // if the user drags the modal down past a threshold, then we auto close the menu
    if (my > latestWindowHeight.current * 0.75) {
      setModalPosition({
        x: mx,
        y: latestWindowHeight.current,
        // onRest: () => console.log('draggable rest'),
        config: { ...defaultSpringConfig, ...commonConfig },
      });
      cancel();
      triggerCloseChatModal();
    }

    // when the user releases the sheet, we check whether it passed
    // the threshold for it to close, or if we reset it to its open position
    if (last) {
      if (my > latestWindowHeight.current * 0.25 || vy > 0.5) {
        // the user has either released the window with less than 75% of it showing or they have
        // released it with a vertical velocity of 0.5 so we assume they want to close the window
        setModalPosition({
          x: mx,
          y: latestWindowHeight.current,
          // onRest: () => console.log('draggable rest'),
          config: { ...defaultSpringConfig, ...commonConfig },
        });
        triggerCloseChatModal();
      } else {
        // the user still has the menu more than 75% open and there is not a negative velocity of
        // greater than the threshold of 0.5
        if (vy < -0.5) {
          // if out vertical velocity is above 0.5 then we have flicked it upwards, so lets use the
          // natural velocity with a little rebound
          setModalPosition({
            x: mx,
            y: my,
            config: { inertia: true, ...commonConfig },
          });
        } else {
          // otherwise we just animate to the fully open window state
          setModalPosition({
            x: mx,
            y: 0,
            config: { ...defaultSpringConfig, ...commonConfig },
          });
        }
      }
    } else {
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      setModalPosition({
        x: mx,
        y: my,
        immediate: true,
        config: { decay: false },
      });
    }
  };

  const triggerCloseChatModal = () => {
    setWindowWidthSpring({ x: 0, immediate: false });
    closeChatModal();
  };

  useEffect(() => {
    if (windowHeight !== previousWindowHeight)
      setModalPosition({ y: open ? 0 : windowHeight, config: defaultSpringConfig });
  }, [windowHeight, open]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Keep chat window scrolled functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [chatStayScrolled, setChatStayScrolled] = useState(0);

  const messagesWrapperRef = useRef(null);
  const [messagesMeasureRef, { height: messagesHeight }] = useMeasure();
  const [userInputMeasureRef, { height: userInputHeight }] = useMeasure();

  const [{ scrollTop }, updateScroll] = useSpring(() => ({ scrollTop: 0, config: defaultSpringConfig }));

  const runScroll = useCallback(offset => updateScroll({
    scrollTop: offset,
    from: { scrollTop: messagesContainerRef.current ? messagesContainerRef.current.scrollTop : 0 },
    immediate: isScrolled() ? true : false,
    reset: true,
  }), [updateScroll]);

  const [messagesContainerRef, scroll, stayScrolled, scrollBottom, isScrolled] = useStayScrolled(null, 30, runScroll);

  const triggerStayScrolled = () => {
    setChatStayScrolled(chatStayScrolled + 1);
  };

  useLayoutEffect(() => {
    if (mainSiteOverlay) {
      // Tell the user to scroll down to see the newest messages if the element wasn't scrolled down
      if (messages.length > 0) {
        const scrolledToBottom = stayScrolled();
        setNewMessageNotification(!scrolledToBottom);
      }
    }
  }, [mainSiteOverlay, messages.length]);

  useLayoutEffect(() => {
    if (mainSiteOverlay) {
      // Either keep the chat window scrolled to the bottom or scroll to the bottom whilst the heights of the windows are changing
      const scrolledToBottom = isScrolled();
      if (scrolledToBottom) {
        scrollBottom();
      } else {
        stayScrolled();
      }
    }
  }, [mainSiteOverlay, messagesHeight, userInputHeight]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      New message notification functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [newMessageNotification, setNewMessageNotification] = useState(false);
  const [newMessageSpring, setNewMessageSpring] = useSpring(() => ({ x: 0, config: defaultSpringConfig }));

  const removeNewMessageNotification = () => {
    setNewMessageNotification(false);
  };

  const onScroll = useCallback(() => {
    const scrolledToBottom = isScrolled();
    if (scrolledToBottom)
      removeNewMessageNotification();
  }, []);

  useEffect(() => { setNewMessageSpring({ x: newMessageNotification ? 1 : 0 }); }, [newMessageNotification]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Notification dot functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (notificationDot && open)
      removeChatNotificationDot();
    else {
      if (previousMessages != null)
        if (!open && (previousMessages.length !== messages.length))
          addChatNotificationDot();
    }
  }, [open, notificationDot, previousMessages, messages]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat script step functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (
      mainSiteOverlay
      && active
      && initialised
      && open
      && messages.length === 0
      && currentScriptStepId == null
      && nextScriptStepId == null
    ) {
      updateNextScriptStepId('intro'); // ourProjects // intro
    }
  }, [mainSiteOverlay, active, initialised, open, messages, currentScriptStepId, nextScriptStepId]);

  const getStepById = (id: string) => {
    const stepFromId = fromChatScript.scriptSteps.filter(step => {
      return step.id === id;
    })[0];

    return stepFromId;
  };

  const getNextStepId = () => {
    const currentStep = getStepById(currentScriptStepId);
    const nextStepId = currentStep.nextStepId as string;
    return nextStepId;
  };

  const [userInputTimeout, setUserInputTimeout] = useState(false);
  const [userInputTimeoutInputId, setUserInputTimeoutInputId] = useState<string>(null);
  const latestUserInputTimeoutInputId = useLatest(userInputTimeoutInputId);

  useTimeout(() => {
    setUserInputTimeout(false);
    updateCurrentUserInputId(latestUserInputTimeoutInputId.current);
  }, userInputTimeout ? 1000 : null);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Trigger next script step functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const triggerNextScriptStep = () => {
    if (currentScriptStepId != null) {
      const currentStep = getStepById(currentScriptStepId);
      const nextStepId = currentStep.nextStepId;
      if (nextStepId != null)
        updateNextScriptStepId(nextStepId as string);
    }
  };

  useEffect(() => {
    if (mainSiteOverlay && active && (currentScriptStepId !== nextScriptStepId)) {
      triggerScriptStepFromId();
    }
  }, [mainSiteOverlay, active, currentScriptStepId, nextScriptStepId]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Script step functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const triggerScriptStepFromId = () => {
    updateCurrentScriptStepId(nextScriptStepId);
    const nextStep = getStepById(nextScriptStepId);

    if (nextStep.type === "message") {
      const nextStepContent = fromChatScript.scriptMessages.filter(message => {
        return message.id === nextStep.contentId;
      })[0];

      triggerNewMessage(
        {
          id: uuidv4(),
          from: nextStepContent.from,
          message: replaceMarkdownImages(nextStepContent.message),
          allowHtml: nextStepContent.allowHtml != null ? nextStepContent.allowHtml : false,
          time: null,
        },
        nextStepContent.writingTime,
      );
    } else if (nextStep.type === "input") {
      setUserInputTimeoutInputId(nextStep.contentId);
      setUserInputTimeout(true);
    } else if (nextStep.type === "submitChat") {
      setTriggerChatSubmission(true);
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      New message functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [newMessageTimeout, setNewMessageTimeout] = useState(false);
  const [newMessageTimeoutMessage, setNewMessageTimeoutMessage] = useState<fromInterfaceOverlay.IMessage>(null);
  const [newMessageTimeoutDelay, setNewMessageTimeoutDelay] = useState(0);

  const handleNewMessageTrigger = () => {
    triggerNewMessage(
      {
        id: uuidv4(),
        from: 'qore',
        message: '' + Math.random(),
        allowHtml: false,
        time: Date.now().toString(),
      },
    );
  };

  const triggerNewMessage = (newMessage: fromInterfaceOverlay.IMessage, delay: number = null) => {
    setNewMessageTimeoutMessage(newMessage);

    const messageDelay = delay != null ? delay : 0;
    setNewMessageTimeoutDelay(messageDelay);

    setNewMessageTimeout(true);
  };

  useTimeout(() => {
    setNewMessageTimeout(false);

    const newMessage = newMessageTimeoutMessage;
    let replacedMessage = newMessage.message;

    latestFormValues.current.map((formValue: fromInterfaceOverlay.IFormValue, index) => {
      if (formValue.type === "inputShortText") {
        const inputProps: fromInterfaceOverlay.IFormValueShortTextProps = formValue.props as fromInterfaceOverlay.IFormValueShortTextProps;
        replacedMessage = replaceMarkdownVariable(replacedMessage, formValue.id, inputProps.value);
      }
      else if (formValue.type === "inputLongText") {
        const inputProps: fromInterfaceOverlay.IFormValueLongTextProps = formValue.props as fromInterfaceOverlay.IFormValueLongTextProps;
        replacedMessage = replaceMarkdownVariable(replacedMessage, formValue.id, inputProps.value);
      }
      else if (formValue.type === "checkboxes") {
        const inputProps: fromInterfaceOverlay.IFormValueCheckboxesProps = formValue.props as fromInterfaceOverlay.IFormValueCheckboxesProps;
        let replacedMessageOptions = '';
        const replacedSelectedOptions: string[] = [];
        inputProps.value.map(value => {

          const currentInput = fromChatScript.scriptInputs.filter(input => {
            return input.id === formValue.id;
          })[0];

          const currentInputProps: fromChatScript.IInputCheckboxesProps = currentInput.props as fromChatScript.IInputCheckboxesProps;

          const newCurrentOption = currentInputProps.options.filter(currentOption => {
            return currentOption.id === value;
          })[0];

          replacedSelectedOptions.push(newCurrentOption.text);

        });

        const amountOfOptions = replacedSelectedOptions.length;

        replacedSelectedOptions.map((selectedOption, optionIndex) => {
          if (optionIndex === 0)
            replacedMessageOptions += selectedOption;
          else if (optionIndex + 1 < amountOfOptions)
            replacedMessageOptions += ', ' + selectedOption;
          else
            replacedMessageOptions += ' and ' + selectedOption;
        });
        replacedMessage = replaceMarkdownVariable(replacedMessage, formValue.id, replacedMessageOptions);
      }
    });

    newMessage.message = replacedMessage;
    addChatMessage(newMessage);
    triggerNextScriptStep();
  }, newMessageTimeout ? newMessageTimeoutDelay : null);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Markdown replacement functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const replaceMarkdownImages = (message: string) => {
    const replacedMessage = message.replace('[heart-emoji]', '(/assets/images/chatHeartEmoji.png "Heart Emoji")');
    return replacedMessage;
  };

  const replaceMarkdownVariable = (message: string, id: string, value: string) => {
    return message.replace(`###$$$${id}$$$###`, value);
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Restart chat functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const restartChat = () => {
    updateUserInputVisibility(false);
    updateCurrentUserInputId(null);
    setNewMessageTimeout(false);
    updateNextScriptStepId('restartChat');
    resetFormValues();
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Show message list functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const getMessages = () => {
    return messages.map((message, index) => (
      <ModalMessage
        key={index}
        from={message.from}
        allowHtml={message.allowHtml}
        triggerStayScrolled={triggerStayScrolled}
      >
        {message.message}
      </ModalMessage>
    ));
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Input validation functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const validateEmail = (value: string, touched: boolean, submitted: boolean) => {
    return touched && submitted ? EmailValidator.validate(value) : true;
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Input submit functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [inputSubmitNextStepId, setInputSubmitNextStepId] = useState<string>(null);
  const latestInputSubmitNextStepId = useLatest(inputSubmitNextStepId);
  const [inputSubmitClicked, setInputSubmitClicked] = useState(false);

  useEffect(() => {
    if (mainSiteOverlay && inputSubmitClicked) {
      const nextStepId = latestInputSubmitNextStepId.current;
      if (nextStepId != null)
        updateNextScriptStepId(nextStepId);

      setInputSubmitNextStepId(null);
      setInputSubmitClicked(false);
    }
  }, [mainSiteOverlay, inputSubmitClicked]);

  // --------------------------------------------------------------      Input short text submit functions
  const handleInputShortTextSubmit = (value: string) => {
    const nextStepId = getNextStepId();
    setInputSubmitNextStepId(nextStepId);

    const inputProps: fromInterfaceOverlay.IFormValueShortTextProps = {
      value,
    };
    handleInputCommonSubmit(inputProps);
  };

  // --------------------------------------------------------------      Input long text submit functions
  const handleInputLongTextSubmit = (value: string) => {
    const nextStepId = getNextStepId();
    setInputSubmitNextStepId(nextStepId);

    const inputProps: fromInterfaceOverlay.IFormValueLongTextProps = {
      value,
    };
    handleInputCommonSubmit(inputProps);
  };

  const getDefaultInputNextStepId = (stepOptions: fromChatScript.IScriptStepNextStepOption[]) => {
    const defaultNextStep = stepOptions.filter(stepOption => {
      return stepOption.options[0] === 'default';
    })[0];
    return defaultNextStep.stepId;
  };

  // --------------------------------------------------------------      Input radio buttons change functions
  const handleRadioButtonsChange = (option: string) => {
    const inputProps: fromInterfaceOverlay.IFormValueRadioButtonsProps = {
      value: option,
    };
    const currentStep = getStepById(currentScriptStepId);
    const nextStepOptions: fromChatScript.IScriptStepNextStepOption[] = currentStep.nextStepId as fromChatScript.IScriptStepNextStepOption[];

    let nextStepId = null;
    nextStepOptions.map(stepOption => {
      if (stepOption.options[0] === option && nextStepId == null)
        nextStepId = stepOption.stepId;
    });

    if (nextStepId == null)
      nextStepId = getDefaultInputNextStepId(nextStepOptions);

    setInputSubmitNextStepId(nextStepId);
    handleInputCommonSubmit(inputProps);
  };

  // --------------------------------------------------------------      Input checkboxes submit functions
  const handleCheckboxesSubmit = (options: string[]) => {
    const inputProps: fromInterfaceOverlay.IFormValueCheckboxesProps = {
      value: options,
    };
    const currentStep = getStepById(currentScriptStepId);
    const nextStepOptions: fromChatScript.IScriptStepNextStepOption[] = currentStep.nextStepId as fromChatScript.IScriptStepNextStepOption[];

    const submittedOptions = options.sort().join();

    let nextStepId = null;
    nextStepOptions.map(stepOption => {
      const stepOptions = stepOption.options.sort().join();
      if (stepOptions === submittedOptions && nextStepId == null)
        nextStepId = stepOption.stepId;
    });

    if (nextStepId == null)
      nextStepId = getDefaultInputNextStepId(nextStepOptions);

    setInputSubmitNextStepId(nextStepId);
    handleInputCommonSubmit(inputProps);
  };

  // --------------------------------------------------------------      Input common submit functions
  const handleInputCommonSubmit = (inputProps: fromInterfaceOverlay.IFormValueOptions = null) => {
    const inputContent = fromChatScript.scriptInputs.filter(input => {
      return input.id === currentUserInputId;
    })[0];

    const newFormValue: fromInterfaceOverlay.IFormValue = {
      id: inputContent.id,
      title: inputContent.title,
      type: inputContent.type,
      props: inputProps,
    };

    if (inputContent.save)
      addFormValue(newFormValue);

    updateCurrentUserInputId(null);
    setInputSubmitClicked(true);
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Show and hide input functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const inputContainerTransitions = useTransition(currentUserInputId, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: defaultSpringConfig,
  });

  const getUserInput = (inputType: string = '') => {
    if (inputType !== '') {
      const inputContent = fromChatScript.scriptInputs.filter(input => {
        return input.id === inputType;
      })[0];

      if (currentUserInputId != null && !userInputVisible)
        updateUserInputVisibility(true);

      if (inputContent.type === "inputShortText") {
        const inputProps: fromChatScript.IInputShortTextProps = inputContent.props as fromChatScript.IInputShortTextProps;
        return (
          <InputShortText
            id={inputProps.id}
            placeholder={inputProps.placeholder}
            initialValue={''}
            onSubmit={handleInputShortTextSubmit}
            customValidation={inputProps.validation === 'email' ? validateEmail : null}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
      else if (inputContent.type === "inputLongText") {
        const inputProps: fromChatScript.IInputLongTextProps = inputContent.props as fromChatScript.IInputLongTextProps;
        return (
          <InputLongText
            id={inputProps.id}
            placeholder={inputProps.placeholder}
            initialValue={''}
            onSubmit={handleInputLongTextSubmit}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
      else if (inputContent.type === "radioButtons") {
        const inputProps: fromChatScript.IInputRadioButtonsProps = inputContent.props as fromChatScript.IInputRadioButtonsProps;
        return (
          <RadioButtons
            id={inputProps.id}
            options={inputProps.options}
            initialOption={null}
            onChange={handleRadioButtonsChange}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
      else if (inputContent.type === "checkboxes") {
        const inputProps: fromChatScript.IInputCheckboxesProps = inputContent.props as fromChatScript.IInputCheckboxesProps;
        return (
          <Checkboxes
            id={inputProps.id}
            options={inputProps.options}
            initialOption={null}
            onSubmit={handleCheckboxesSubmit}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
    }

    return null;
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Submit chat functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [triggerChatSubmission, setTriggerChatSubmission] = useState(false);

  useEffect(() => {
    if (mainSiteOverlay && triggerChatSubmission) {
      setTriggerChatSubmission(false);

      const formDetails = {
        details: formValues,
      };

      api.post('email/contact', formDetails)
        .then(response => {
          response = response.data;
          chatSubmittedSuccessfully(response);
        })
        .catch(error => {
          //
          const response = error.response.data;
          chatSubmitError(response);
        });
      //}
    }
  }, [mainSiteOverlay, triggerChatSubmission]);

  const chatSubmittedSuccessfully = response => {
    triggerNextScriptStep();
  };

  const chatSubmitError = response => {
    updateNextScriptStepId(currentScriptStepId + 'Error');
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Styles for all modal window elements
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const getModalHeightScale = (y: number) => ((windowHeight - y) / windowHeight);

  const overlayStyles = {
    opacity: modalYPosition.to(y => 0.7 * getModalHeightScale(y)),
    display: modalYPosition.to(y => getModalHeightScale(y) === 0 ? 'none' : 'block'),
  };

  const windowStyles = {
    width: windowWidthSpring.x.to(x => `calc((${x} * (100% - ${triggerWidth}px)) + ${triggerWidth}px)`),
    height: modalYPosition.to(y => `calc((${getModalHeightScale(y)} * (100% - ${triggerWidth}px)) + ${triggerWidth}px)`),
    display: windowWidthSpring.x.to(x => x === 0 ? 'none' : 'block'),
    opacity: windowWidthSpring.x.to(x => open ? (x < 0.3 ? x * (1 / 0.3) : 1) : (x < 0.3 ? x * (1 / 0.3) : 1)),
  };

  const sbw = useDisabledScrollWidth();

  const chatModalWindowWrapperStyles = {
    // marginRight: sbw,
    marginRight: sbw.width.to(x => x),
  };

  const chatContainerStyles = {
    opacity: modalYPosition.to(y => getModalHeightScale(y)),
  };

  const newMessageNotificationStyles = {
    opacity: newMessageSpring.x.to(x => x),
    display: newMessageSpring.x.to(x => x === 0 ? `none` : `block`),
  };

  const triggerStyles = {
    right: windowWidthSpring.x.to(x => `${x * 50}%`),
  };

  const triggerButton = (
    <a.div className='triggerContainer' style={triggerStyles}>
      <ModalTrigger
        mainSiteOverlay={mainSiteOverlay}
        backgroundShade={backgroundShade}
        updateWindowDimensions={updateWindowDimensions}
      />
    </a.div>
  );

  if (mainSiteOverlay)
    return (
      <Fragment>
        <a.div
          className={classNames(
            'chatModalOverlay',
            { open },
          )}
          style={overlayStyles}
          onClick={closeChatModal}
        />
        <div className='triggerNotificationSpacer' ref={triggerNotificationElement} />
        <a.div
          className='chatModalWindowWrapper'
          ref={windowWrapper}
          style={chatModalWindowWrapperStyles}
        >
          <a.div
            className={classNames(
              'chatModalWindow',
              { open },
            )}
            style={windowStyles}
          >
            <div className='windowContainer'>
              <a.div
                className={classNames(
                  'dragBarHandle',
                  { isBeingDragged },
                )}
                {...dragBarBind()}
              >
                <a.div className='bar' />
              </a.div>
              <a.div
                className={classNames(
                  'chatContainer',
                  { isBeingDragged },
                )}
                style={chatContainerStyles}
                {...chatContainerBind()}
              >
                <a.div ref={messagesContainerRef} className='messagesContainer' scrollTop={scrollTop} onScroll={onScroll}>
                  <div ref={messagesWrapperRef} className='messagesWrapper spacer'>
                    <div ref={messagesMeasureRef} className='measurementWrapper' />
                    {getMessages()}
                  </div>
                </a.div>
                <div className='userInputContainer' ref={userInputMeasureRef}>
                  {inputContainerTransitions((values, item) => {
                    const commonProps = {
                      style: values,
                    };

                    if (item != null)
                      return <a.div {...commonProps}>{getUserInput(item)}</a.div>;
                    else
                      return null;
                  })}
                </div>
                <div className='controlsContainer'>
                  {/*<div className='triggerNewMessage' onClick={handleNewMessageTrigger}>New Message</div>*/}
                  <div className='toContactPage'>
                    <SimpleLink to='/contact' title='Give me other contact options' onClick={closeChatModal}>
                      Give me other contact options.
                    </SimpleLink>
                  </div>
                  <div className='restartChat'>
                    <SimpleLink title='Let me start again' onClick={restartChat}>
                      I messed up! Let me start again.
                    </SimpleLink>
                  </div>
                </div>
                <a.div
                  className='unreadMessagesNotification'
                  style={newMessageNotificationStyles}
                >
                  <div className='text' onClick={scrollBottom}>
                    Unread Messages
                  </div>
                  <div className='closeButton' onClick={removeNewMessageNotification} />
                </a.div>
              </a.div>
            </div>
          </a.div>
          {triggerButton}
        </a.div>
      </Fragment>
    );
  else
    return (
      <a.div
        className='chatModalWindowWrapper'
        style={chatModalWindowWrapperStyles}
      >
        {triggerButton}
      </a.div>
    );
};

export default ChatModal;
