import React, { useState, useEffect, useRef, MutableRefObject, createRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import classNames from 'classnames';

import { a } from 'react-spring';
import { useChatAnimation } from 'hooks/useChatAnimation';

import { ButtonLink } from 'components/Links/Index';

import { usePrevious, useLatest } from 'utils/Index';
import { useHotkeyHints } from './useHotkeyHints';
import { useOptionsKeys } from './useOptionsKeys';

export interface IProps {
  id: string;
  options: ICheckboxOption[];
  initialOption: string;
  optionOnEnable?: string; // the option to set when the input is enabled for editing
  optionOnDisable?: string; // the option to set when the input is disabled
  setRef?(ref): void;
  onTouched?(): any;
  setOption?(option: string): any;
  onChange?(option: string): any;
  onSubmit?(options: string[]): any;
  customValidation?(selectedOptions: string[], touched: boolean, submitted: boolean): boolean;
  onEnable?(): any;
  onDisable?(): any;
  onBlur?(): any;
  setError?(error: boolean): any;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  inputVisibility: boolean;
  triggerStayScrolled: () => void;
}

export interface ICheckboxOption {
  id: string;
  text: string;
}

export const Checkboxes: React.FC<IProps> = ({
  id,
  options,
  initialOption,
  optionOnEnable,
  optionOnDisable,
  setRef,
  required,
  disabled,
  inputVisibility,
  triggerStayScrolled = () => null,
  ...props
}) => {

  const [
    inputWrapperRef,
    initialised,
    heightSpring,
    inputVisible,
    showInput,
  ] = useChatAnimation(
    triggerStayScrolled,
    inputVisibility,
  );

  const inputWrapperStyles = {
    height: !initialised ? heightSpring.x.to(x => x) : null,
    overflow: 'hidden',
  };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [touched, setTouched] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const previousInitialOption = usePrevious(initialOption);
  const previousDisabled = usePrevious(disabled);

  useEffect(() => {
    if (initialOption !== previousInitialOption && initialOption != null)
      setSelectedOptions([
        ...selectedOptions,
        initialOption,
      ]);

    if (disabled && !previousDisabled)
      onDisable();
    else if (!disabled && previousDisabled)
      onEnable();
  }, [disabled, previousDisabled]);

  const onDisable = () => {
    if (props.onDisable)
      props.onDisable();

    //if (optionOnDisable != null)
    //    setOption(optionOnDisable);
  };

  const onEnable = () => {
    if (props.onEnable)
      props.onEnable();

    //if (optionOnEnable != null)
    //    setOption(optionOnEnable);
    //else
    //    setOption(selectedOption); // this is there to make sure that the option stored in the wrapper component initialises with the same value as this radio button
  };

  //const setOption = (option: string = null) => {
  //    setSelectedOptions([
  //        ...selectedOptions,
  //        option
  //    ]);
  //    setError(false);

  //    if (props.setOption)
  //        props.setOption(option);
  //}

  const onChange = (optionId: string) => {
    const newSelectedOptions: string[] = Object.assign([], selectedOptions);
    if (newSelectedOptions.indexOf(optionId) > -1) {
      newSelectedOptions.splice(newSelectedOptions.indexOf(optionId), 1);
    } else {
      newSelectedOptions.push(optionId);
    }
    setSelectedOptions(newSelectedOptions);
    setTouched(true);

    const validationPassed = validate(newSelectedOptions, true, submitted);
    setError(!validationPassed);

    if (props.onChange)
      props.onChange(optionId);

    if (props.onTouched)
      props.onTouched();
  };

  const latestError = useLatest(error);
  const latestSelectedOptions = useLatest(selectedOptions);

  const validate = (newSelectedOptions: string[], lTouched: boolean, lSubmitted: boolean) => {
    const fieldError: boolean = (((newSelectedOptions.length < 1) && (required && lSubmitted)) && lTouched);
    let customValidationPassed: boolean = true;

    if (props.customValidation)
      customValidationPassed = props.customValidation(newSelectedOptions, lTouched, lSubmitted); // if the customValidation returns true then we assume that we have passed validation

    const validationPassed = (!fieldError && customValidationPassed);

    if (props.setError)
      props.setError(!validationPassed);

    return validationPassed;
  };

  const onBlurHandler = () => {
    if (props.onBlur)
      props.onBlur();
  };

  const handleSubmit = () => {
    setTouched(true);
    setSubmitted(true);

    const validationPassed = validate(latestSelectedOptions.current, true, true);
    setError(!validationPassed);

    if (!latestError.current && validationPassed) {
      showInput(false);
      if (props.onSubmit)
        props.onSubmit(latestSelectedOptions.current);
    }
  };

  // Hook up keyboard hotkeys to allow users to select options through their keyboard
  const optionsRef = useRef<Array<MutableRefObject<HTMLInputElement>>>([...Array(options.length)].map(() => createRef()));
  useOptionsKeys(options.length, optionsRef);

  // Hook up ? hotkey to show hotkey hints
  const [hotkeySpring] = useHotkeyHints();
  const hotkeyStyles = {
    opacity: hotkeySpring.o,
  };

  useHotkeys('enter', () => {
    handleSubmit();
  });

  const checkboxOption = (optionId: string, checked: boolean, text: string, index: number) => (
    <div className='optionWrapper' key={optionId}>
      <a.div className='hotkey' style={hotkeyStyles}>{index + 1}</a.div>
      <input
        ref={optionsRef.current[index]}
        type="checkbox"
        name={id}
        id={optionId}
        value={optionId}
        checked={checked}
        onChange={() => onChange(optionId)}
        onClick={() => onChange(optionId)}
        onBlur={onBlurHandler}
        disabled={disabled}
        className={classNames(
          { error: error || (props.error && touched) },
        )}
      />
      <label htmlFor={optionId}>
        <div className='icon'>
          <div className='line' />
        </div>
        {text}
      </label>
    </div>
  );

  return (
    <a.div
      style={inputWrapperStyles}
    >
      <div
        ref={inputWrapperRef}
        className={classNames(
          'userInputWrapper',
          'checkboxWrapper',
        )}
      >
        <div className='optionsWrapper'>
          {options.map((option, index) => checkboxOption(option.id, selectedOptions.indexOf(option.id) > -1, option.text, index))}
        </div>
        <div className='ctaWrapper'>
          <ButtonLink
            type='medium'
            arrow
            animationReady={true}
            animateIn={inputVisible}
            title='Send'
            onClick={handleSubmit}
          >
            Send
          </ButtonLink>
        </div>
      </div>
    </a.div>
  );
};
