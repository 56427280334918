import { MutableRefObject } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export const useOptionsKeys = (
  numberOfOptions: number = 1,
  optionsRef: MutableRefObject<Array<MutableRefObject<HTMLInputElement>>>,
) => {
  useHotkeys([...Array(numberOfOptions)].map((option, index) => index + 1).join(','), (event, handler) => {
    event.preventDefault();
    const optionElem = optionsRef.current[parseInt(handler.key, 10) - 1].current;
    optionElem.click();
  });
};
