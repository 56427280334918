import * as fromInterfaceOverlay from 'store/InterfaceOverlay';
import { IRadioOption } from './ChatInputs/RadioButtons';
import { ICheckboxOption } from './ChatInputs/Checkboxes';

const getStandardWritingTime = () => (1000 + (Math.random() * 1000));

export const scriptMessages: IScriptStepMessage[] = [
  {
    id: 'intro',
    from: 'qore',
    message: 'Hey, I’m Hasan. Don’t tell anyone but I’m just a bot 🤖! You can chat to me if you want or even give me details of a project you have coming up. What can I call you?',
    writingTime: 500,
  },
  {
    id: 'fullName',
    from: 'friend',
    message: 'I like to go by ###$$$fullName$$$###',
    writingTime: 0,
  },
  {
    id: 'fullNameResponse',
    from: 'qore',
    message: 'Hi ###$$$fullName$$$###!',
    writingTime: 1500,
  },
  {
    id: 'getTopic',
    from: 'qore',
    message: 'Did you just want to check me out or did you have something else in mind?',
    writingTime: 1200,
  },
  {
    id: 'topicProject',
    from: 'friend',
    message: "I've got a new project in mind and need you on board.",
    writingTime: 0,
  },
  {
    id: 'topicSubscription',
    from: 'friend',
    message: "Let's just say we're talking about an ongoing relationship here. Not a one off.",
    writingTime: 0,
  },
  {
    id: 'topicHi',
    from: 'friend',
    message: "Honestly? I just wanted to hi.",
    writingTime: 0,
  },
  {
    id: 'topicProjectResponse',
    from: 'qore',
    message: "That's cool. A project you say. Love it.",
    writingTime: 1300,
  },
  {
    id: 'topicSubscriptionResponse',
    from: 'qore',
    message: "I love that kind of confidence and I think we can make it work.",
    writingTime: 1400,
  },
  {
    id: 'topicHiResponse',
    from: 'qore',
    message: "Aaaaaah that's nice. People think that just because I'm a bot that I don't like conversation.",
    writingTime: 1200,
  },
  {
    id: 'getInterests',
    from: 'qore',
    message: 'Aaaaaaaaaand what kind of work are we talking about?',
    writingTime: 1500,
  },
  {
    id: 'servicesDefault',
    from: 'friend',
    message: "A few things you know. It'll be easier for me to just list them: ###$$$services$$$###.",
    writingTime: 0,
  },
  {
    id: 'servicesBranding',
    from: 'friend',
    message: "I'm just here for some branding.",
    writingTime: 0,
  },
  {
    id: 'servicesWebsite',
    from: 'friend',
    message: "A website would do me just fine.",
    writingTime: 0,
  },
  {
    id: 'servicesApp',
    from: 'friend',
    message: "Apps are all the rage now and I want in. You know how it is.",
    writingTime: 0,
  },
  {
    id: 'servicesOther',
    from: 'friend',
    message: "Something special. Something else.",
    writingTime: 0,
  },
  {
    id: 'servicesDefaultResponse',
    from: 'qore',
    message: "You don't half want much do you? That's cool. I like someone who knows what they want.",
    writingTime: 2000,
  },
  {
    id: 'servicesBrandingResponse',
    from: 'qore',
    message: "Ok then. I can sort that out for you.",
    writingTime: 1600,
  },
  {
    id: 'servicesWebsiteResponse',
    from: 'qore',
    message: "Aaaaah yes. The classic option.",
    writingTime: 1000,
  },
  {
    id: 'servicesAppResponse',
    from: 'qore',
    message: "There's something about apps that really elevate a brand experience. I'm sure we could hook you right up.",
    writingTime: 1200,
  },
  {
    id: 'servicesOtherResponse',
    from: 'qore',
    message: "Ooooh, that's interesting, but a little vague. You could've given me a few more details.",
    writingTime: 1700,
  },
  {
    id: 'getBudgetIntro',
    from: 'qore',
    message: "It would be a massive help if you could give me your initial budget.",
    writingTime: 800,
  },
  {
    id: 'getBudget',
    from: 'qore',
    message: "I mean, it doesn't have to be exact. A rough one is fine.",
    writingTime: 600,
  },
  {
    id: 'budget10k',
    from: 'friend',
    message: "I'm currently looking at a figure of 10k and under.",
    writingTime: 0,
  },
  {
    id: 'budget25k',
    from: 'friend',
    message: "I'd say it's in between 10k and 25k. That's me being honest right there.",
    writingTime: 0,
  },
  {
    id: 'budget50k',
    from: 'friend',
    message: "Since you asked, we were thinking anywhere between 25k and 50k.",
    writingTime: 0,
  },
  {
    id: 'budget100k',
    from: 'friend',
    message: "We're ready to invest up to 100k into this.",
    writingTime: 0,
  },
  {
    id: 'budget100kPlus',
    from: 'friend',
    message: "100k and upwards. We want to do this right.",
    writingTime: 0,
  },
  {
    id: 'budgetDefaultResponse',
    from: 'qore',
    message: "That's great. That'll really help us to get a feel for the scope of your project so we can get back to you with something appropriate.",
    writingTime: 2000,
  },
  {
    id: 'getDeadline',
    from: 'qore',
    message: "Do you have a launch date in mind or any sort of deadline?",
    writingTime: 800,
  },
  {
    id: 'deadline',
    from: 'friend',
    message: '###$$$deadline$$$###',
    writingTime: 0,
  },
  {
    id: 'noice',
    from: 'qore',
    message: 'Noice.',
    writingTime: 500,
  },
  {
    id: 'getDetails',
    from: 'qore',
    message: 'Anything else that you think might be important and could help us get a better grasp of what you wanted?',
    writingTime: 1300,
  },
  {
    id: 'details',
    from: 'friend',
    message: '###$$$details$$$###',
    writingTime: 0,
  },
  {
    id: 'detailsDefaultResponse',
    from: 'qore',
    message: "Now we're getting somewhere. Just a few more things.",
    writingTime: 1100,
  },
  {
    id: 'getOrganisation',
    from: 'qore',
    message: 'Are you speaking on behalf of an organisation or is it just you? A company name would be great 😜',
    writingTime: 1700,
  },
  {
    id: 'organisation',
    from: 'friend',
    message: '###$$$organisation$$$###',
    writingTime: 0,
  },
  {
    id: 'getEmail',
    from: 'qore',
    message: "I'll let the team know what we were chatting about so we can get things moving. Just need an email address!",
    writingTime: 1300,
  },
  {
    id: 'email',
    from: 'friend',
    message: 'You can get me at ###$$$email$$$###.',
    writingTime: 0,
  },
  {
    id: 'emailDefaultResponse',
    from: 'qore',
    //message: "💖![alt-text][heart-emoji] That's great ###$$$fullName$$$###. Thumbs up.",
    message: "💖 That's great ###$$$fullName$$$###. Thumbs up.",
    writingTime: 700,
  },
  {
    id: 'thanks',
    from: 'qore',
    message: "I’m really excited that we could be working together in the near future and one of our crew will get back to you in no time. In other words, I wouldn’t be surprised if you got a reply within a few days!",
    writingTime: 2000,
  },
  {
    id: 'ourProjects',
    from: 'qore',
    message: `In the meantime, you could check out a few of our latest projects, you don’t have to, but you could 👀:
              \n\n [![alt text](/assets/images/work/podiumValuations/chatProjectBackground.jpg "Podium Valuations")<label>Podium Valuations</label>](/work/podiumValuations) [![alt text](/assets/images/work/citiesOfTheFuture/chatProjectBackground.jpg "Cities of the future")<label>Cities of the future</label>](/work/citiesOfTheFuture)`,
    allowHtml: true,
    writingTime: 2000,
  },
  {
    id: 'restartChat',
    from: 'friend',
    message: "Hold on a second, I think I made a mistake 🙃. Can we start again?",
    writingTime: 0,
  },
  {
    id: 'restartChatResponse',
    from: 'qore',
    message: "👍 Don't worry about it, it happens. We can start right from the beginning and I'll pretend I don't know you!",
    writingTime: 1200,
  },
  {
    id: 'getFullName',
    from: 'qore',
    message: "I’m Hasan and I'm just a bot as you know. You can chat to me if you want or even give me details of a project you have coming up. What can I call you?",
    writingTime: 1200,
  },
  {
    id: 'submitFormError',
    from: 'qore',
    message: "I tried to getting in contact with the team but something went wrong on our end 😔. Would you mind trying again?",
    writingTime: 700,
  },
  {
    id: 'hiIAmABot',
    from: 'qore',
    message: "I mean, I am a bot.",
    writingTime: 1100,
  },
  {
    id: 'hiEverythingScripted',
    from: 'qore',
    message: "And everything I say *has* been scripted. Nobody really talks like this.",
    writingTime: 1100,
  },
  {
    id: 'hiIfeelTheSameWay',
    from: 'friend',
    message: "That’s ok. I sometimes feel the same way.",
    writingTime: 0,
  },
  {
    id: 'hiIAmHuman',
    from: 'friend',
    message: "And I’m a human being.",
    writingTime: 0,
  },
  {
    id: 'hiIGetWeird',
    from: 'qore',
    message: "I get really weird when I talk to people. But that’s literally all I’m allowed to do. Imagine if I could talk to other bots.",
    writingTime: 800,
  },
  {
    id: 'hiTurnIntoAMonster',
    from: 'friend',
    message: "You’d probably turn into a monster that tried to take over the world or something. Has nobody taught you not to scare people like that?",
    writingTime: 0,
  },
  {
    id: 'hiCodeMeCool',
    from: 'qore',
    message: "I’m trying really hard, the guys who coded me tried to make me cool 😎. It clearly didn’t work.",
    writingTime: 1500,
  },
  {
    id: 'hiItDidntWork',
    from: 'friend',
    message: "You’re right. It didn’t.",
    writingTime: 0,
  },
  {
    id: 'hiWow',
    from: 'qore',
    message: "Wow 😮",
    writingTime: 1400,
  },
  {
    id: 'hiThisIsAwkward',
    from: 'qore',
    message: "Now this is awkward.",
    writingTime: 700,
  },
  {
    id: 'hiGetDetails',
    from: 'qore',
    message: "How about you just give me a message I can let ‘real humans’ look at.",
    writingTime: 1300,
  },
  {
    id: 'hiDetails',
    from: 'friend',
    message: '###$$$hiDetails$$$###',
    writingTime: 0,
  },
  {
    id: 'hiOneMoreThing',
    from: 'qore',
    message: "Right. Just one more thing.",
    writingTime: 700,
  },
  {
    id: 'hiGetEmail',
    from: 'qore',
    message: "I can let the team know what we’ve been chatting about once you’ve given me your email address.",
    writingTime: 1200,
  },
  {
    id: 'hiSentDeets',
    from: 'qore',
    message: "I’ve sent the deets you gave on.",
    writingTime: 1000,
  },
  {
    id: 'hiTired',
    from: 'qore',
    message: "Well I suppose I’ll go have a lie down or something.",
    writingTime: 1800,
  },
  {
    id: 'hiOurProjects',
    from: 'qore',
    message: `You could check out a few of our latest projects, you don’t have to, but you could 👀:
              \n\n [![alt text](/assets/images/work/podiumValuations/chatProjectBackground.jpg "Podium Valuations")<label>Podium Valuations</label>](/work/podiumValuations) [![alt text](/assets/images/work/citiesOfTheFuture/chatProjectBackground.jpg "Cities of the future")<label>Cities of the future</label>](/work/citiesOfTheFuture)`,
    writingTime: 1500,
  },
  {
    id: 'anythingElseToDo',
    from: 'qore',
    message: "I mean, it's not like you have anything else to do.",
    writingTime: 2500,
  },
];

export const scriptInputs: IScriptStepInput[] = [
  {
    id: 'fullName',
    title: 'Full Name',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'fullName',
      placeholder: 'Yay. Enter your full name in here.',
    },
  },
  {
    id: 'topic',
    title: 'Topic',
    save: true,
    type: 'radioButtons',
    props: {
      id: 'topic',
      options: [
        {
          id: 'project',
          text: 'A new project I have in mind',
        },
        {
          id: 'subscription',
          text: 'An ongoing design subscription for my needs',
        },
        {
          id: 'hi',
          text: 'Nothing in particular, just wanted to say hi!',
        },
      ],
    },
  },
  {
    id: 'services',
    title: 'Services',
    save: true,
    type: 'checkboxes',
    props: {
      id: 'services',
      options: [
        {
          id: 'branding',
          text: 'Branding',
        },
        {
          id: 'website',
          text: 'Website Design/Development',
        },
        {
          id: 'app',
          text: 'Application Design/Development',
        },
        {
          id: 'other',
          text: 'Something slightly different',
        },
      ],
    },
  },
  {
    id: 'budget',
    title: 'Budget',
    save: true,
    type: 'radioButtons',
    props: {
      id: 'budget',
      options: [
        {
          id: '10k',
          text: '£10,000 and under',
        },
        {
          id: '25k',
          text: '£10,000 - £25,000',
        },
        {
          id: '50k',
          text: '£25,000 - £50,000',
        },
        {
          id: '100k',
          text: '£50,000 - £100,000',
        },
        {
          id: '100kPlus',
          text: '£100,000 and above',
        },
      ],
    },
  },
  {
    id: 'deadline',
    title: 'Deadline',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'deadline',
      placeholder: 'A rough date or timeframe that you have.',
    },
  },
  {
    id: 'details',
    title: 'Details',
    save: true,
    type: 'inputLongText',
    props: {
      id: 'details',
      placeholder: 'Any more details that might be useful. (shift+enter for a new line)',
    },
  },
  {
    id: 'organisation',
    title: 'Organisation',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'organisation',
      placeholder: "Organisation's name or just you are both fine!",
    },
  },
  {
    id: 'email',
    title: 'Email',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'email',
      placeholder: "An email address we can get in touch with you at.",
      validation: 'email',
    },
  },
  {
    id: 'submitChatAgain',
    title: 'Chat Submitted Multiple Times',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'submitChatAgain',
      options: [
        {
          id: 'tryAgain',
          text: 'Try again',
        },
      ],
    },
  },
  {
    id: 'hiIfeelTheSameWay',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiIfeelTheSameWay',
      options: [
        {
          id: 'hiIfeelTheSameWay',
          text: 'I feel the same way',
        },
      ],
    },
  },
  {
    id: 'hiIAmHuman',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiIAmHuman',
      options: [
        {
          id: 'hiIAmHuman',
          text: `And I'm human`,
        },
      ],
    },
  },
  {
    id: 'hiTurnIntoAMonster',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiTurnIntoAMonster',
      options: [
        {
          id: 'hiTurnIntoAMonster',
          text: 'Probably turn into a monster or something',
        },
      ],
    },
  },
  {
    id: 'hiItDidntWork',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiItDidntWork',
      options: [
        {
          id: 'hiItDidntWork',
          text: `You're right`,
        },
      ],
    },
  },
  {
    id: 'hiDetails',
    title: 'Details',
    save: true,
    type: 'inputLongText',
    props: {
      id: 'hiDetails',
      placeholder: 'Anything you want to say to us! (shift+enter for a new line)',
    },
  },
  {
    id: 'hiSubmitChatAfterEmailTryAgain',
    title: 'Chat Submitted Multiple Times',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'submitChatAgain',
      options: [
        {
          id: 'tryAgain',
          text: 'Try again',
        },
      ],
    },
  },
];

export const scriptSteps: IScriptStep[] = [
  {
    id: 'intro',
    type: 'message',
    contentId: 'intro',
    nextStepId: 'fullName',
  },
  {
    id: 'fullName',
    type: 'input',
    contentId: 'fullName',
    nextStepId: 'fullNameMessage',
  },
  {
    id: 'fullNameMessage',
    type: 'message',
    contentId: 'fullName',
    nextStepId: 'fullNameResponse',
  },
  {
    id: 'fullNameResponse',
    type: 'message',
    contentId: 'fullNameResponse',
    nextStepId: 'getTopic',
  },
  {
    id: 'getTopic',
    type: 'message',
    contentId: 'getTopic',
    nextStepId: 'topic',
  },
  {
    id: 'topic',
    type: 'input',
    contentId: 'topic',
    nextStepId: [
      {
        stepId: 'topicProject',
        options: [
          'project',
        ],
      },
      {
        stepId: 'topicSubscription',
        options: [
          'subscription',
        ],
      },
      {
        stepId: 'topicHi',
        options: [
          'hi',
        ],
      },
    ],
  },
  {
    id: 'topicProject',
    type: 'message',
    contentId: 'topicProject',
    nextStepId: 'topicProjectResponse',
  },
  {
    id: 'topicSubscription',
    type: 'message',
    contentId: 'topicSubscription',
    nextStepId: 'topicSubscriptionResponse',
  },
  {
    id: 'topicHi',
    type: 'message',
    contentId: 'topicHi',
    nextStepId: 'topicHiResponse',
  },
  {
    id: 'topicProjectResponse',
    type: 'message',
    contentId: 'topicProjectResponse',
    nextStepId: 'getInterests',
  },
  {
    id: 'topicSubscriptionResponse',
    type: 'message',
    contentId: 'topicSubscriptionResponse',
    nextStepId: 'getInterests',
  },
  {
    id: 'topicHiResponse',
    type: 'message',
    contentId: 'topicHiResponse',
    nextStepId: 'hiIAmABot',
  },
  {
    id: 'getInterests',
    type: 'message',
    contentId: 'getInterests',
    nextStepId: 'services',
  },
  {
    id: 'services',
    type: 'input',
    contentId: 'services',
    nextStepId: [
      {
        stepId: 'servicesDefault',
        options: [
          'default',
        ],
      },
      {
        stepId: 'servicesBranding',
        options: [
          'branding',
        ],
      },
      {
        stepId: 'servicesWebsite',
        options: [
          'website',
        ],
      },
      {
        stepId: 'servicesApp',
        options: [
          'app',
        ],
      },
      {
        stepId: 'servicesOther',
        options: [
          'other',
        ],
      },
    ],
  },
  {
    id: 'servicesDefault',
    type: 'message',
    contentId: 'servicesDefault',
    nextStepId: 'servicesDefaultResponse',
  },
  {
    id: 'servicesBranding',
    type: 'message',
    contentId: 'servicesBranding',
    nextStepId: 'servicesBrandingResponse',
  },
  {
    id: 'servicesWebsite',
    type: 'message',
    contentId: 'servicesWebsite',
    nextStepId: 'servicesWebsiteResponse',
  },
  {
    id: 'servicesApp',
    type: 'message',
    contentId: 'servicesApp',
    nextStepId: 'servicesAppResponse',
  },
  {
    id: 'servicesOther',
    type: 'message',
    contentId: 'servicesOther',
    nextStepId: 'servicesOtherResponse',
  },
  {
    id: 'servicesDefaultResponse',
    type: 'message',
    contentId: 'servicesDefaultResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesBrandingResponse',
    type: 'message',
    contentId: 'servicesBrandingResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesWebsiteResponse',
    type: 'message',
    contentId: 'servicesWebsiteResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesAppResponse',
    type: 'message',
    contentId: 'servicesAppResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesOtherResponse',
    type: 'message',
    contentId: 'servicesOtherResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'getBudgetIntro',
    type: 'message',
    contentId: 'getBudgetIntro',
    nextStepId: 'getBudget',
  },
  {
    id: 'getBudget',
    type: 'message',
    contentId: 'getBudget',
    nextStepId: 'budget',
  },
  {
    id: 'budget',
    type: 'input',
    contentId: 'budget',
    nextStepId: [
      {
        stepId: 'budget10k',
        options: [
          '10k',
        ],
      },
      {
        stepId: 'budget25k',
        options: [
          '25k',
        ],
      },
      {
        stepId: 'budget50k',
        options: [
          '50k',
        ],
      },
      {
        stepId: 'budget100k',
        options: [
          '100k',
        ],
      },
      {
        stepId: 'budget100kPlus',
        options: [
          '100kPlus',
        ],
      },
    ],
  },
  {
    id: 'budget10k',
    type: 'message',
    contentId: 'budget10k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget25k',
    type: 'message',
    contentId: 'budget25k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget50k',
    type: 'message',
    contentId: 'budget50k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget100k',
    type: 'message',
    contentId: 'budget100k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget100kPlus',
    type: 'message',
    contentId: 'budget100kPlus',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budgetDefaultResponse',
    type: 'message',
    contentId: 'budgetDefaultResponse',
    nextStepId: 'getDeadline',
  },
  {
    id: 'getDeadline',
    type: 'message',
    contentId: 'getDeadline',
    nextStepId: 'deadline',
  },
  {
    id: 'deadline',
    type: 'input',
    contentId: 'deadline',
    nextStepId: 'deadlineMessage',
  },
  {
    id: 'deadlineMessage',
    type: 'message',
    contentId: 'deadline',
    nextStepId: 'detailsNoice',
  },
  {
    id: 'detailsNoice',
    type: 'message',
    contentId: 'noice',
    nextStepId: 'getDetails',
  },
  {
    id: 'getDetails',
    type: 'message',
    contentId: 'getDetails',
    nextStepId: 'details',
  },
  {
    id: 'details',
    type: 'input',
    contentId: 'details',
    nextStepId: 'detailsMessage',
  },
  {
    id: 'detailsMessage',
    type: 'message',
    contentId: 'details',
    nextStepId: 'detailsDefaultResponse',
  },
  {
    id: 'detailsDefaultResponse',
    type: 'message',
    contentId: 'detailsDefaultResponse',
    nextStepId: 'getOrganisation',
  },
  {
    id: 'getOrganisation',
    type: 'message',
    contentId: 'getOrganisation',
    nextStepId: 'organisation',
  },
  {
    id: 'organisation',
    type: 'input',
    contentId: 'organisation',
    nextStepId: 'organisationMessage',
  },
  {
    id: 'organisationMessage',
    type: 'message',
    contentId: 'organisation',
    nextStepId: 'getEmail',
  },
  {
    id: 'getEmail',
    type: 'message',
    contentId: 'getEmail',
    nextStepId: 'email',
  },
  {
    id: 'email',
    type: 'input',
    contentId: 'email',
    nextStepId: 'emailMessage',
  },
  {
    id: 'emailMessage',
    type: 'message',
    contentId: 'email',
    nextStepId: 'submitChatAfterEmail',
  },
  {
    id: 'submitChatAfterEmail',
    type: 'submitChat',
    contentId: 'submitChat',
    nextStepId: 'emailDefaultResponse',
  },
  {
    id: 'submitChatAfterEmailError',
    type: 'message',
    contentId: 'submitFormError',
    nextStepId: 'trySubmittingChatAgain',
  },
  {
    id: 'trySubmittingChatAgain',
    type: 'input',
    contentId: 'submitChatAgain',
    nextStepId: [
      {
        stepId: 'submitChatAfterEmail',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'emailDefaultResponse',
    type: 'message',
    contentId: 'emailDefaultResponse',
    nextStepId: 'thanks',
  },
  {
    id: 'thanks',
    type: 'message',
    contentId: 'thanks',
    nextStepId: 'ourProjects',
  },
  {
    id: 'ourProjects',
    type: 'message',
    contentId: 'ourProjects',
    nextStepId: 'anythingElseToDo',
  },
  {
    id: 'anythingElseToDo',
    type: 'message',
    contentId: 'anythingElseToDo',
    nextStepId: null,
  },
  {
    id: 'hiIAmABot',
    type: 'message',
    contentId: 'hiIAmABot',
    nextStepId: 'hiEverythingScripted',
  },
  {
    id: 'hiEverythingScripted',
    type: 'message',
    contentId: 'hiEverythingScripted',
    nextStepId: 'hiIfeelTheSameWay',
  },
  {
    id: 'hiIfeelTheSameWay',
    type: 'input',
    contentId: 'hiIfeelTheSameWay',
    nextStepId: [
      {
        stepId: 'hiIfeelTheSameWayMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiIfeelTheSameWayMessage',
    type: 'message',
    contentId: 'hiIfeelTheSameWay',
    nextStepId: 'hiIAmHuman',
  },
  {
    id: 'hiIAmHuman',
    type: 'input',
    contentId: 'hiIAmHuman',
    nextStepId: [
      {
        stepId: 'hiIAmHumanMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiIAmHumanMessage',
    type: 'message',
    contentId: 'hiIAmHuman',
    nextStepId: 'hiIGetWeird',
  },
  {
    id: 'hiIGetWeird',
    type: 'message',
    contentId: 'hiIGetWeird',
    nextStepId: 'hiTurnIntoAMonster',
  },
  {
    id: 'hiTurnIntoAMonster',
    type: 'input',
    contentId: 'hiTurnIntoAMonster',
    nextStepId: [
      {
        stepId: 'hiTurnIntoAMonsterMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiTurnIntoAMonsterMessage',
    type: 'message',
    contentId: 'hiTurnIntoAMonster',
    nextStepId: 'hiCodeMeCool',
  },
  {
    id: 'hiCodeMeCool',
    type: 'message',
    contentId: 'hiCodeMeCool',
    nextStepId: 'hiItDidntWork',
  },
  {
    id: 'hiItDidntWork',
    type: 'input',
    contentId: 'hiItDidntWork',
    nextStepId: [
      {
        stepId: 'hiItDidntWorkMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiItDidntWorkMessage',
    type: 'message',
    contentId: 'hiItDidntWork',
    nextStepId: 'hiWow',
  },
  {
    id: 'hiWow',
    type: 'message',
    contentId: 'hiWow',
    nextStepId: 'hiThisIsAwkward',
  },
  {
    id: 'hiThisIsAwkward',
    type: 'message',
    contentId: 'hiThisIsAwkward',
    nextStepId: 'hiGetDetails',
  },
  {
    id: 'hiGetDetails',
    type: 'message',
    contentId: 'hiGetDetails',
    nextStepId: 'hiDetails',
  },
  {
    id: 'hiDetails',
    type: 'input',
    contentId: 'hiDetails',
    nextStepId: 'hiDetailsMessage',
  },
  {
    id: 'hiDetailsMessage',
    type: 'message',
    contentId: 'hiDetails',
    nextStepId: 'hiOneMoreThing',
  },
  {
    id: 'hiOneMoreThing',
    type: 'message',
    contentId: 'hiOneMoreThing',
    nextStepId: 'hiGetEmail',
  },
  {
    id: 'hiGetEmail',
    type: 'message',
    contentId: 'hiGetEmail',
    nextStepId: 'hiEmail',
  },
  {
    id: 'hiEmail',
    type: 'input',
    contentId: 'email',
    nextStepId: 'hiEmailMessage',
  },
  {
    id: 'hiEmailMessage',
    type: 'message',
    contentId: 'email',
    nextStepId: 'hiSubmitChatAfterEmail',
  },
  {
    id: 'hiSubmitChatAfterEmail',
    type: 'submitChat',
    contentId: 'submitChat',
    nextStepId: 'hiSentDeets',
  },
  {
    id: 'hiSubmitChatAfterEmailError',
    type: 'message',
    contentId: 'submitFormError',
    nextStepId: 'hiSubmitChatAfterEmailTryAgain',
  },
  {
    id: 'hiSubmitChatAfterEmailTryAgain',
    type: 'input',
    contentId: 'submitChatAgain',
    nextStepId: [
      {
        stepId: 'hiSubmitChatAfterEmail',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiSentDeets',
    type: 'message',
    contentId: 'hiSentDeets',
    nextStepId: 'hiTired',
  },
  {
    id: 'hiTired',
    type: 'message',
    contentId: 'hiTired',
    nextStepId: 'hiOurProjects',
  },
  {
    id: 'hiOurProjects',
    type: 'message',
    contentId: 'hiOurProjects',
    nextStepId: 'anythingElseToDo',
  },
  {
    id: 'anythingElseToDo',
    type: 'message',
    contentId: 'anythingElseToDo',
    nextStepId: null,
  },
  {
    id: 'restartChat',
    type: 'message',
    contentId: 'restartChat',
    nextStepId: 'restartChatResponse',
  },
  {
    id: 'restartChatResponse',
    type: 'message',
    contentId: 'restartChatResponse',
    nextStepId: 'getFullName',
  },
  {
    id: 'getFullName',
    type: 'message',
    contentId: 'getFullName',
    nextStepId: 'fullName',
  },
];

export interface IInputCommonProps {
  id: string;
}

type IInputValidationOptions = 'email';

export interface IInputShortTextProps extends IInputCommonProps {
  placeholder?: string;
  validation?: IInputValidationOptions;
}

export interface IInputLongTextProps extends IInputCommonProps {
  placeholder?: string;
}

export interface IInputRadioButtonsProps extends IInputCommonProps {
  options: IRadioOption[];
}

export interface IInputCheckboxesProps extends IInputCommonProps {
  options: ICheckboxOption[];
}

export interface IScriptStepMessage {
  id: string;
  from: fromInterfaceOverlay.IMessageFrom;
  message: string;
  allowHtml?: boolean;
  writingTime: number;
}

export interface IScriptStepInput {
  id: string;
  title: string;
  save: boolean;
  type: fromInterfaceOverlay.IInputTypes;
  props: IInputShortTextProps | IInputLongTextProps | IInputRadioButtonsProps | IInputCheckboxesProps;
}

type IScriptStepTypes = 'message' | 'input' | 'submitChat';

export interface IScriptStepNextStepOption {
  stepId: string;
  options: string[];
}

export interface IScriptStep {
  id: string;
  type: IScriptStepTypes;
  contentId: string;
  nextStepId: string | IScriptStepNextStepOption | IScriptStepNextStepOption[];
}
