import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

import { a } from 'react-spring';
import { useChatAnimation } from 'hooks/useChatAnimation';

import { ButtonLink } from 'components/Links/Index';

import { usePrevious } from 'utils/Index';

export interface IProps {
  id: string;
  placeholder?: string;
  initialValue: string;
  valueOnEnable?: string; // the value to set when the input is enabled for editing
  valueOnDisable?: string; // the value to set when the input is disabled
  setRef?(ref): void;
  onTouched?(): any;
  setValue?(value: string): any;
  onChange?(value: string): any;
  onSubmit?(value: string): any;
  customValidation?(value: string, touched: boolean, submitted: boolean): boolean;
  onEnable?(): any;
  onDisable?(): any;
  onBlur?(): any;
  setError?(error: boolean): any;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  inputVisibility: boolean;
  autocomplete?: boolean;
  triggerStayScrolled: () => void;
}

export const InputShortText: React.FC<IProps> = ({
  id,
  placeholder,
  initialValue = '',
  valueOnEnable,
  valueOnDisable,
  setRef,
  required,
  disabled,
  inputVisibility,
  autocomplete = false,
  triggerStayScrolled = () => null,
  ...props
}) => {

  const [
    inputWrapperRef,
    initialised,
    heightSpring,
    inputVisible,
    showInput,
  ] = useChatAnimation(
    triggerStayScrolled,
    inputVisibility,
  );

  const inputWrapperStyles = {
    height: !initialised ? heightSpring.x.to(x => x) : null,
    overflow: 'hidden',
  };

  const [value, setValue] = useState<string>('');
  const [touched, setTouched] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const previousInitialValue = usePrevious(initialValue);
  const previousDisabled = usePrevious(disabled);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (initialValue !== previousInitialValue)
      setValue(initialValue);

    if (disabled && !previousDisabled)
      onDisable();
    else if (!disabled && previousDisabled)
      onEnable();
  }, [disabled, previousDisabled]);

  const onDisable = () => {
    if (props.onDisable)
      props.onDisable();

    if (valueOnDisable != null)
      setInputValue(valueOnDisable);
  };

  const onEnable = () => {
    if (props.onEnable)
      props.onEnable();

    if (valueOnEnable != null)
      setInputValue(valueOnEnable);
    else
      setInputValue(value); // this is there to make sure that the value stored in the wrapper component initialises with the same value as this radio button
  };

  const setInputValue = (newValue: string = null) => {
    setValue(newValue);
    setError(false);

    if (props.setValue)
      props.setValue(newValue);
  };

  const onChange = () => {
    const newValue = inputRef.current.value;

    setValue(newValue);
    setTouched(true);

    const validationPassed = validate(newValue, true, submitted);
    setError(!validationPassed);

    if (props.onChange)
      props.onChange(newValue);

    if (props.onTouched)
      props.onTouched();
  };

  const onKeyDown = e => {
    if (e.key === 'Enter')
      handleSubmit();
  };

  const focusInput = () => {
    inputRef.current.focus();
  };

  const validate = (newValue: string, lTouched: boolean, lSubmitted: boolean) => {
    const fieldError: boolean = (((newValue === '' || newValue.trim() === '' || newValue == null) && (required && lSubmitted)) && lTouched);
    let customValidationPassed: boolean = true;

    if (props.customValidation)
      customValidationPassed = props.customValidation(newValue, lTouched, lSubmitted); // if the customValidation returns true then we assume that we have passed validation

    const validationPassed = (!fieldError && customValidationPassed);

    if (props.setError)
      props.setError(!validationPassed);

    return validationPassed;
  };

  const onBlurHandler = () => {
    if (props.onBlur)
      props.onBlur();
  };

  const handleSubmit = () => {
    setTouched(true);
    setSubmitted(true);

    const validationPassed = validate(value, true, true);
    setError(!validationPassed);

    if (!error && validationPassed) {
      showInput(false);
      if (props.onSubmit)
        props.onSubmit(value);
    }
  };

  return (
    <a.div
      style={inputWrapperStyles}
    >
      <div
        ref={inputWrapperRef}
        className={classNames(
          'userInputWrapper',
          'inputWrapper',
        )}
      >
        <div className='inputTextWrapper' onClick={focusInput}>
          <input
            ref={inputRef}
            type="text"
            id={id}
            name={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlurHandler}
            //onResize={this.onResize}
            disabled={disabled}
            className={classNames(
              { error: error || (props.error && touched) },
            )}
            autoComplete={autocomplete ? "on" : "off"}
          />
        </div>
        <div className='ctaWrapper'>
          <ButtonLink
            type='medium'
            arrow
            animationReady={true}
            animateIn={inputVisible}
            title='Send'
            onClick={handleSubmit}
          >
            Send
          </ButtonLink>
        </div>
      </div>
    </a.div>
  );
};
