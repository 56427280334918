import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { a, useSpring, SpringConfig, SpringValue } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { useTimeout } from 'utils/Index';

interface ISpringInterface {
  [o: string]: SpringValue<any>;
  o: SpringValue<number>;
}

export const useHotkeyHints = () => {
  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 450,
    friction: 24,
  };

  const [hotkeySpring, setHotkeySpring] = useSpring(() => ({ o: 0, config: springConfig }));
  const [hideHotkeyTimeout, setHideHotkeyTimeout] = useState(false);

  useTimeout(() => {
    setHotkeySpring({ o: 0 });
    setHideHotkeyTimeout(false);
  }, hideHotkeyTimeout ? 1500 : null);

  useHotkeys('shift+/', (event, handler) => {
    event.preventDefault();
    setHotkeySpring({ o: 1 });
    setHideHotkeyTimeout(true);
  });

  const returnValues: [ISpringInterface] = [hotkeySpring];

  return returnValues;
};
